<template>
    <div>
        <card-header title="Edit Payment Method" icon="fa-pencil"/>

        <card-body>
            <data-row title="Type">Credit Card</data-row>
            <data-row title="Last">{{method.last}}</data-row>
            <data-row title="Expires">{{expires | moment('MMMM (MM), YYYY')}}</data-row>
            <data-row title="Auth.net">{{method.authnet_payment_profile_id}}</data-row>
            <data-row title="Added">{{method.created_at | moment('MMMM Do, YYYY, h:mm A')}}</data-row>
            <data-row title="Updated">{{method.updated_at | moment('MMMM Do, YYYY, h:mm A')}}</data-row>

            <card-list>
                <separator-list-item/>

                <subheader-list-item icon="fa-align-left" title="Credit Card Information"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Last 4 Digits" label-position="inside">
                                <b-input v-model="method.last"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Expiration Month (MM)" label-position="inside">
                                <b-input v-model="method.exp_month" placeholder="MM"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Expiration Year (YYYY)" label-position="inside">
                                <b-input v-model="method.exp_year" placeholder="YYYY"></b-input>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-align-left" title="Authorize.net"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Customer Profile ID (Override)" label-position="inside">
                                <b-input v-model="method.authnet_customer_profile_id"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Payment Profile ID" label-position="inside">
                                <b-input v-model="method.authnet_payment_profile_id"></b-input>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>

                <separator-list-item/>
                <container-list-item padded>
                    Warning: Changing and of this information DOES NOT update Authorize.net.  This is only intended to make corrections when info becomes out of sync.  Changing the Authorize.net IDs must match records stored on Authorize.net or payments will fail.
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteMethod"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Method?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="false" @click="submit"><i class="fas fa-check mr-3"></i>Save Payment Method</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import DataRow from "../../TIER/components/DataRow";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";

    export default {
        props: ['card', 'props'],
        components: {SeparatorListItem, DataRow, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                method: {},
                update: {},
                dirty: null,
                confirmDelete: false
            };
        },
        computed: {
            expires: function() {
                if (!this.method.expiration)
                    return null;
                return new Date(this.method.expiration.substr(2,4), this.method.expiration.substr(0,2)-1)
            }
        },
        methods: {
            loadMethod: function () {
                this.$emit('loading', true);
                http.get('/api/payment_plans/' + this.props.planId + '/method/' + this.props.methodId, {force: true}).then(response => {
                    response.data.exp_month = response.data.expiration.substring(0,2);
                    response.data.exp_year = response.data.expiration.substring(2,6);

                    this.update = {...this.data}

                    this.method = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                console.log({
                    id: this.method.id,
                    last: this.method.last,
                    expiration: this.method.exp_month.padStart(2, '0') + this.method.exp_year,
                    authnet_customer_profile_id: parseInt(this.method.authnet_customer_profile_id),
                    authnet_payment_profile_id: parseInt(this.method.authnet_payment_profile_id)
                });
                this.$emit('loading', true);
                http.put('/api/payment_plans/' + this.method.id + '/method/' + this.props.methodId, {
                    id: this.method.id,
                    last: this.method.last,
                    expiration: this.method.exp_month.padStart(2, '0') + this.method.exp_year,
                    authnet_customer_profile_id: parseInt(this.method.authnet_customer_profile_id),
                    authnet_payment_profile_id: parseInt(this.method.authnet_payment_profile_id)
                }, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.method.id});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteMethod() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/payment_plans/' + this.props.planId + '/method/' + this.props.methodId).then(response => {
                        this.$reloadCard('payment-plan', {planId: this.props.planId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.methodId': function () {
                this.dirty = null;
                this.loadMethod();
            },
            'method': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this payment method? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadMethod();
        }
    };
</script>
